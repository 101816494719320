<div
  class="NgxEditor__Dropdown--Text"
  [class.NgxEditor__Dropdown--Selected]="isSelected"
  [class.NgxEditor--Disabled]="isDropdownDisabled"
  (mousedown)="toggleDropdown($event)"
>
  {{ getName(activeItem || group) | async }}
</div>

<div class="NgxEditor__Dropdown--DropdownMenu" *ngIf="isDropdownOpen">
  <div
    class="NgxEditor__Dropdown--Item"
    *ngFor="let item of items; trackBy: trackByIndex"
    (mousedown)="onClick($event, item)"
    [ngClass]="{
      'NgxEditor__Dropdown--Active': item === activeItem,
      'NgxEditor--Disabled': disabledItems.includes(item)
    }"
  >
    {{ getName(item) | async }}
  </div>
</div>
