<ng-container *ngFor="let toolbarItem of toolbar; let lastToolbarItem = last; trackBy: trackByIndex">
  <ng-container *ngFor="let item of toolbarItem; let lastItem = last; trackBy: trackByIndex">
    <div
      class="NgxBubbleMenu__Icon"
      *ngIf="toggleCommands.includes(item)"
      [ngClass]="{
        'NgxBubbleMenu__Icon--Active': this.activeItems.includes(item),
        'NgxEditor--Disabled': !this.execulableItems.includes(item)
      }"
      (mousedown)="onClick($event, item)"
      [title]="getTitle(item) | async"
      [innerHTML]="getIcon(item)"
    ></div>
    <div class="NgxBubbleMenu__Seperator" *ngIf="lastItem && !lastToolbarItem"></div>
  </ng-container>
</ng-container>
