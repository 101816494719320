<div
  class="NgxEditor__MenuItem--IconContainer"
  [class.NgxEditor__MenuItem--Active]="isActive || showPopup"
  [class.NgxEditor--Disabled]="!canExecute"
  [innerHTML]="icon | sanitizeHtml"
  (mousedown)="onMouseDown($event)"
  [title]="title | async"
></div>

<!-- popup -->
<div *ngIf="showPopup" class="NgxEditor__Popup">
  <form class="NgxEditor__Popup--Form" [formGroup]="form" (ngSubmit)="insertLink($event)">
    <div class="NgxEditor__Popup--FormGroup">
      <div class="NgxEditor__Popup--Col">
        <label class="NgxEditor__Popup--Label" [htmlFor]="getId('link-popup-url')">{{ getLabel('url') | async }}</label>
        <input type="href" [id]="getId('link-popup-url')" formControlName="href" autocomplete="off" />
        <div *ngIf="href.touched && href.invalid" class="NgxEditor__HelpText NgxEditor__HelpText--Error">
          {{ href.errors?.['pattern'] && 'Please enter valid url.' }}
        </div>
      </div>
    </div>

    <div class="NgxEditor__Popup--FormGroup">
      <div class="NgxEditor__Popup--Col">
        <label class="NgxEditor__Popup--Label" [htmlFor]="getId('link-popup-label')">{{
          getLabel('text') | async
        }}</label>
        <input type="text" [id]="getId('link-popup-label')" formControlName="text" autocomplete="off" />
        <div *ngIf="text.touched && text.invalid" class="NgxEditor__HelpText NgxEditor__HelpText--Error">
          {{ text.errors?.['required'] && 'This is required' }}
        </div>
      </div>
    </div>

    <div class="NgxEditor__Popup--FormGroup" *ngIf="this.options.showOpenInNewTab">
      <div class="NgxEditor__Popup--Col">
        <label>
          <input type="checkbox" formControlName="openInNewTab" />
          {{ getLabel('openInNewTab') | async }}
        </label>
      </div>
    </div>

    <button type="submit" [disabled]="!form.valid">{{ getLabel('insert') | async }}</button>
  </form>
</div>
